import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Welcome from './Welcome';
import Login from './Login';

function Simulator() {
  useEffect(() => {
    // force a reload on root path, which should load the simulator if we have logged in
    console.log('Load simulator');
    window.location.href = '/simulator';
  });

  return (
    <div>Simulator</div>
  );
}

function Main() {
  useEffect(() => {
    // check if aready logged in
    fetch('/api/login')
        .then((response) => {
          if (response.ok) {
            console.log('Already logged in - load simulator');
            window.location.href = '/simulator';
          } else {
            console.log('Not logged in: ' + response.text);
          }
        })
        .catch((err) => {
          console.log('Catch: ' + err.message);
        });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Welcome}/>
        <Route path="/login" Component={Login}/>
        <Route path="/simulator" Component={Simulator}/>
        <Route render={() => <h1>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
